@import url(./fonts/index.css);

body {
  margin: 0;
  font-family: 'Inter', 'Times New Roman';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
