@font-face {
    font-family: 'Inter';
    src: url(./Inter-Bold.woff) formmat(woff);
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(./Inter-Light.woff) format(woff);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(./Inter-Regular.woff) format(woff);
    font-weight: 400;
    font-style: normal;
}