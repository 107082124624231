.grid-space {
  padding: 20px 50px;
  display: flex;
  background-color: white;
  gap: 3rem;
}

.img-wrapper {
  width: 100%;
}

.img-wrapper-carousal {
  width: 100%;
}

.send-button {
  border: 1px solid #004b24;
  background-color: #01843f;
  color: #ffea02;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 11px;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 1);
  cursor: pointer;
}

@media (min-width: 768px) {
  .img-wrapper-carousal {
    height: 100vh;
  }

  .send-button {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .grid-space {
    padding: 10px 30px;
    position: relative;
    gap: 0.7rem;
  }

  .text-font {
    font-size: 12px !important;
  }

  .heading-text {
    font-size: 15px !important;
  }
  .slider-wrapper {
    height: 100%;
  }

  .carousel-wra-img {
    height: 45vh;
  }

  .carousel-height {
    height: 100%;
  }
  .carousel-height > div {
    height: 100%;
  }

  .img-wrapper-carousal {
    height: 100%;
    object-fit: cover;
  }

  .img-wrapper {
    width: 80%;
  }
}
