.grid-space {
  padding: 20px 50px;
  display: flex;
  gap: 2rem;
  background-color: white;
}

.img-wrapper {
  width: 100%;
}

.img-wrapper-carousal {
  width: 100%;
}

.send-button {
  border: 1px solid #004b24;
  background-color: #01843f;
  color: #ffea02;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 11px;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer {
  display: flex;
  flex-direction: column;
}

.resend {
  color: #01843f;
  outline: 0;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 15px;
}

/* Disabled button style */
.resend:disabled {
  color: #999; /* Faded text color when disabled */
  cursor: not-allowed; /* Show a "not-allowed" cursor when disabled */
}

@media (min-width: 768px) {
  .img-wrapper-carousal {
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
  }

  .send-button {
    font-size: 18px;
  }

  .grid-space {
    height: 100vh;
  }
}

@media (max-width: 576px) {
  .grid-space {
    gap: 0.5rem;
    position: relative;
    padding: 10px 30px;
  }

  .actions {
    flex-wrap: wrap;
  }
  .img-wrapper {
    display: none;
  }


  .text-font {
    font-size: 12px !important;
  }

  .heading-text {
    font-size: 15px !important;
  }

  .resend {
    font-size: 12px !important;
  }

  .carousel-wrap-otp {
    height: 45vh;
  }

  .img-wrapper-carousal {
    object-fit: cover;
    height: 100%;
  }
}

@media (max-width: 850px) and (min-width: 600px) {
  .img-wrapper-carousal {
    display: none;
  }

  .grid-space {
    height: 90vh;
  }
}
