.background-water-image {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/images/waterPlant.jpg");
  background-position: center;
  background-size: cover;
}

.thank-you {
  text-align: center;
  padding: 70px 20px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  background: #00000090;
  box-shadow: 0px 0px 30px 0px rgba(97, 97, 97, 0.2);
  filter: drop-shadow(2px 4px 6px black);
  position: relative;
}

.img-logo {
  background-color: snow;
  margin: 15px 0;
  width: 70%;
}

.circle {
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  border: 1px solid #e4e4e4;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  /* margin-top: -45px; */
}

.img-gif {
  height: 55px;
  padding: 15px;
}


