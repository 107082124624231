input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
@media (max-width: 576px) {
  .carousel .slider-wrapper {
    height: 100%;
  }
  .carousel .slider-wrapper > ul {
    height: 100%;
  }
  body {
    overflow-x: hidden;
  }
}
