.form-controlled {
  padding: 30px 0;
}

.img-wrapper-carousal {
  width: 50%;
  position: fixed;
}

.send-button {
  border: 1px solid #004b24;
  background-color: #01843f;
  color: #ffea02;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 11px;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 1);
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

@media (max-width: 850px) {
  .img-show {
    display: none;
  }
}

@media (min-width: 768px) {
  .img-wrapper-carousal {
    height: 100vh;
  }

  .send-button {
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  .kisan-icon {
    width: 244px;
  }
}
